<template>
  <view-item title="库存流水">
    <template #operation>
      <button-ajax type="primary" :method="operation.export.click">导出</button-ajax>
    </template>

    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <table-dynamic
            code="IVHSTFD"
            :data="table.data"
            :loading="table.loading"
            :height="height"
            :method="table.get"
            pagination
            :paging="table.paging"
            show-summary
            :summary="table.summary.data"
            :config="table.config"
          ></table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/inventory/history/export',
                params(params) {
                  params.payload = this.table.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          }
        }
      },
      table: {
        cascader: ['hhh', 'zzz'],
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/history/query',
            summary: 'GET /enocloud/inventory/history/summary',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          },
          GOODS_OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsOem'
              }
            }
          },
          GOODS_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsName'
              }
            }
          },
          GOODS_BARCODE: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.barcode'
              }
            }
          },
          GOODS_MODEL: {
            header: {
              filter: {
                type: 'text',
                field: 'goods.model'
              }
            }
          },
          GOODS_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'goodsType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['GOODSTP'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          GOODS_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'categoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/goods/category', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: { action: 'GET /enocloud/common/warehouse', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'supplierName'
              }
            }
          },
          BATCH_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'batchNo'
              }
            }
          },
          DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          DOCUMENT_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'documentSerialNo'
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'preparedBy'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpecs'
              }
            }
          },
          SUB_TYPE: { header: { filter: { type: 'text', field: 'subType' } } },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  multiple: true,
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (value) => ({ quickSearch: value })
                  },
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'cascader',
                field: 'inventoryHistoryTypes',
                props: {
                  multiple: true,
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['INVHISCTYTYPE'])
                  },
                  props: { label: 'message', value: '', valueKey: 'code' }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
